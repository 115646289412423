import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import MapsActionTypes from "./maps.types";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearMapsError,
	fetchTrucksFailure,
	fetchTrucksSuccess,
	fetchTreesFailure,
	fetchTreesSuccess,
	setGeoJSON,
} from "./maps.action";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../../redux/company/company.selector";
import { generateGeoJSON } from "../../helpers/Maps.helper";


export function* fetchTruckLog({ payload }) {
	const company = yield select(selectCurrentUserCompany);
	const info =  payload;
	const query = queryString.stringify({
		...info,
		fltDeleted: 0,
		pageSize: 0,
		sord: 'asc',
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/track-loggings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const track = response.data.records;

		if(track != ""){
			yield put(
				setGeoJSON(
					generateGeoJSON({ data: track, info: "track"  })
				)
			);
			}
		else{
			yield put(
				setGeoJSON(
					generateGeoJSON({data: null, info: null})
				)
			);
		}
		yield put(fetchTrucksSuccess(track));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchTrucksFailure(errorMessage));
	}
}

export function* onFetchTrucksStart() {
	yield takeLatest(MapsActionTypes.FETCH_TRUCKS_START, fetchTruckLog);
}

export function* fetchTreeLog({ payload }) {
	const company = yield select(selectCurrentUserCompany);
	const info =  payload;
	const query = queryString.stringify({
		...info,
		fltDeleted: 0,
		pageSize: 0,
		sord: 'asc',
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/planting-events?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const track = response.data.records;

		if(!!track){
			yield put(
				setGeoJSON(
					generateGeoJSON({ data: track, info: "tree" })
				)
			);
			}
		else{
			yield put(
				setGeoJSON(
					generateGeoJSON({data: null, info: null})
				)
			);
		}
		yield put(fetchTreesSuccess(track));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearMapsError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchTreesFailure(errorMessage));
	}
}

export function* onFetchTreesStart() {
	yield takeLatest(MapsActionTypes.FETCH_TREES_START, fetchTreeLog);
}


export function* mapsSagas() {
	yield all([
		call(onFetchTrucksStart),
		call(onFetchTreesStart),
	]);
}
