import QCActivityActionTypes from "./wood-cubic.types";

export const fetchCubicStart = (filters) => ({
	type: QCActivityActionTypes.FETCH_CUBIC_START,
	payload: filters,
});

export const fetchCubicSuccess = (members) => ({
	type: QCActivityActionTypes.FETCH_CUBIC_SUCCESS,
	payload: members,
});

export const fetchCubicFailure = (error) => ({
	type: QCActivityActionTypes.FETCH_CUBIC_FAILURE,
	payload: error,
});


export const clearCubicError = () => ({
	type: QCActivityActionTypes.CLEAR_ERROR,
});
