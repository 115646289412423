import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearQCError,
	fetchQCFailure,
	fetchQCSuccess,
	fetchQCPhotoFailure,
	fetchQCPhotoSuccess,
} from "./wood-qc.action";
import QCActionTypes from "./wood-qc.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser } from "../user/user.selector";

export function* fetchQC({ payload }) {
	const filters = payload;
	const userCompany = yield select(selectCurrentUserCompany);
	const user = yield select(selectCurrentUser);
	const query = queryString.stringify({
		fltDeleted:0,
		fltUser: user.id,
		pageSize: 0,
		...filters
	});

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/wood-qc?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const harvestActivities = response.data.records;
		yield put(fetchQCSuccess(harvestActivities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearQCError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchQCFailure(errorMessage));
	}
}

export function* onFetchQCStart() {
	yield takeLatest(
		QCActionTypes.FETCH_QC_START,
		fetchQC
	);
}


export function* QCSagas() {
	yield all([call(onFetchQCStart)]);
}
