// return entity level

export const generateGeoJSON = ({ data, info }) => {
	let initialPoint = [112.997777999999997, -1.80299999999999994];

	let features = [];

	
		let geometry = {
			coordinates: [],
		};
		if(!!data && info == "track"){
			geometry.type = "LineString";
			data.forEach((data) => {
			geometry.coordinates.push([data.longitude, data.latitude]);
			}
		);

			let feature = {
				type: "Feature",
					properties: {
						...data,
					},
					geometry,
				};

				features.push(feature);
				
		}

		else if (!!data && info == "tree"){

			geometry.type = "MultiPoint";

			data.forEach((data) => {
				geometry.coordinates.push([data.uncorrectedLongitude, data.uncorrectedLatitude]);
			})
			
			let feature = {
				type: "Feature",
					properties: {
						...data,
					},
					geometry,
				};

			features.push(feature);
		}
		
		
		const geoJSON = {
			type: "FeatureCollection",
			features: features,
		};

	return geoJSON;
};
