import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearWoodlogCountingError,
	fetchWoodlogCountingFailure,
	fetchWoodlogCountingSuccess,
} from "./woodlog-counting.actions";
import WoodLogCountingTypes from "./woodlog-counting.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser } from "../user/user.selector";

export function* fetchWoodlogCounting({ payload }) {
	const filters = payload;
	const userCompany = yield select(selectCurrentUserCompany);
	const user = yield select(selectCurrentUser);
	const query = queryString.stringify({
		fltCompanyId: userCompany.id,
		fltUser: user.id,
		pageSize: 0,
		fltUseCaseType : "1",
		...filters
	});

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/wood-countings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const woodlogCountings = response.data.records;
		yield put(fetchWoodlogCountingSuccess(woodlogCountings));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearWoodlogCountingError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchWoodlogCountingFailure(errorMessage));
	}
}

export function* onFetchWoodlogCountingStart() {
	yield takeLatest(
		WoodLogCountingTypes.FETCH_WOODLOG_COUNTING_START,
		fetchWoodlogCounting
	);
}

export function* woodlogCountingSagas() {
	yield all([call(onFetchWoodlogCountingStart)]);
}
