import WoodLogCountingTypes from "./woodlog-counting.types";

export const fetchWoodlogCountingStart = (filters) => ({
    type: WoodLogCountingTypes.FETCH_WOODLOG_COUNTING_START,
    payload: filters,
})

export const fetchWoodlogCountingSuccess = (members) => ({
    type: WoodLogCountingTypes.FETCH_WOODLOG_COUNTING_SUCCESS,
    payload: members
})

export const fetchWoodlogCountingFailure = (error) => ({
    type: WoodLogCountingTypes.FETCH_WOODLOG_COUNTING_FAILURE,
    payload: error
})

export const clearWoodlogCountingError = () => ({
    type: WoodLogCountingTypes.CLEAR_ERROR
})