const ActivitiesActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	//Fetch PLANTER
	FETCH_PLANTER_START: "FETCH_PLANTER_START",
	FETCH_PLANTER_SUCCESS: "FETCH_PLANTER_SUCCESS",
	FETCH_PLANTER_FAILURE: "FETCH_PLANTER_FAILURE",
	//Fetch TREE
	FETCH_PLANTER_TREE_START: "FETCH_PLANTER_TREE_START",
	FETCH_PLANTER_TREE_SUCCESS: "FETCH_PLANTER_TREE_SUCCESS",
	FETCH_PLANTER_TREE_FAILURE: "FETCH_PLANTER_TREE_FAILURE",
};

export default ActivitiesActionTypes;
