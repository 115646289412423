import {
	KEYCLOAK_ID,
	REDIRECT_URI_LOGOUT,
	SERVER_ENV,
} from "../../configs/env";
import queryString from "query-string";

const KeycloakLogout = () => {
	const baseUrlKeycloak =
		SERVER_ENV === "development"
			? "accounts-dev.semaigroup.com"
			: "accounts.semaigroup.com";
	return queryString.stringifyUrl({
		url: `https://${baseUrlKeycloak}/realms/semai-x/protocol/openid-connect/logout`,
		query: {
			client_id: KEYCLOAK_ID,
			post_logout_redirect_uri: REDIRECT_URI_LOGOUT,
		},
	});
};

export default KeycloakLogout();
