import axios from "axios";
import moment from "moment";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import { selectCurrentUser } from "../user/user.selector";
import {
	clearActivitiesError,
	fetchActivitiesPlanSuccess,
	fetchActivitiesPlanFailure,
	createActivitiesPlanSuccess,
	createActivitiesPlanFailure,
	updateActivitiesPlanSuccess,
	updateActivitiesPlanFailure,
	fetchActivitiesPlanStart,
} from "./activity-planning.action";
import ActivityPlanActionTypes from "./activity-planning.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";

export function* fetchActivities({payload}) {
	const company = yield select(selectCurrentUserCompany);
	const filters= payload;
	const query = queryString.stringify({
		fltCompany: company.id,
		fltDeleted: 0,
		pageSize: 0,
		...filters
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/activity-plans?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchActivitiesPlanSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchActivitiesPlanFailure(errorMessage));
	}
}

export function* onFetchActivitiesStart() {
	yield takeLatest(
		ActivityPlanActionTypes.FETCH_ACTIVITIES_PLAN_START,
		fetchActivities
	);
}


export function* createActivitiesPlan({ payload }) {
	const currentUser = yield select(selectCurrentUser);
	const activitiesInfo = payload;
	try {
		const uuid = yield axios({
			method: "GET",
			url: "https://www.uuidgenerator.net/api/version1",
		});

		const response = yield axios({
			method: "POST",
			url: `${BASE_URL_API}/activity-plans`,
			data: {
				activityId: activitiesInfo.activity,
				blockId: activitiesInfo.block,
				isDeleted: 0,
				createdBy: currentUser.username,
				createdTime: moment().format("YYYY-MM-DD[T]HH:mm:ssZZ"),
				planningDate: moment(activitiesInfo.planDate).format("YYYY-MM-DD"),
				deletedBy: null,
				deletedTime: null,
				id: uuid.data,
				userId: currentUser.id,
				username:currentUser.username,
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const snackbarData = {
			message: "Rencana Aktivitas Berhasil Dimasukan",
			color: "success",
			place: "bc",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createActivitiesPlanSuccess());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(createActivitiesPlanFailure(errorMessage));
	}
}

export function* onCreateActivitiesStart() {
	yield takeLatest(ActivityPlanActionTypes.CREATE_ACTIVITIES_PLAN_START, createActivitiesPlan);
}

export function* updateActivitiesPlan({ payload }) {
	const activitiesInfo = payload;
	try {

		const response = yield axios({
			method: "PUT",
			url: `${BASE_URL_API}/activity-plans/${activitiesInfo.id}`,
			data: {
				planningDate: moment(activitiesInfo.planDate).format("YYYY-MM-DD"),
			},
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		yield put(updateActivitiesPlanSuccess());
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearActivitiesError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(updateActivitiesPlanFailure(errorMessage));
	}
}

export function* onUpdateActivitiesStart() {
	yield takeLatest(ActivityPlanActionTypes.UPDATE_ACTIVITIES_PLAN_START, updateActivitiesPlan);
}

export function* activityPlanningSagas() {
	yield all([
		call(onFetchActivitiesStart),
		call(onCreateActivitiesStart),
		call(onUpdateActivitiesStart)
	]);
}
