import UserActionTypes from "./user.types";

const INITIAL_STATE = {
	// general
	error: null,
	loading: false,
	// auth
	currentUser: null,
	rememberMe: false,
	token: null,
	refreshToken: null,
	idToken: null,
	provider: null,
	role: null,

	allUsers: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UserActionTypes.FETCH_ALL_USERS_SUCCESS:
			return {
				...state,
				allUsers: action.payload,
				loading: false,
			};
		case UserActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case UserActionTypes.FETCH_USER_START:
		case UserActionTypes.GET_ACCESS_TOKEN_START:
		case UserActionTypes.CHECK_ACCESS_TOKEN_START:
		case UserActionTypes.REFRESH_ACCESS_TOKEN_START:
		case UserActionTypes.USER_LOGOUT_START:
		case UserActionTypes.REGISTER_USER_START:
		case UserActionTypes.FETCH_ALL_USERS_START:
		case UserActionTypes.UPDATE_USER_START:
		case UserActionTypes.RESET_USER_PASSWORD_START:
		case UserActionTypes.FORGOT_PASS_START:
		case UserActionTypes.INSERT_USER_START:
		case UserActionTypes.DELETE_USER_START:
		case UserActionTypes.IMPORT_USERS_START:
			return {
				...state,
				loading: true,
			};
		case UserActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				currentUser: { ...action.payload },
				error: null,
				loading: false,
			};
		case UserActionTypes.SIGN_OUT_SUCCESS:
			return {
				...state,
				loading: false,
				currentUser: null,
			};
		case UserActionTypes.FETCH_USER_FAILURE:
		case UserActionTypes.GET_ACCESS_TOKEN_FAILURE:
		case UserActionTypes.CHECK_ACCESS_TOKEN_FAILURE:
		case UserActionTypes.REFRESH_ACCESS_TOKEN_FAILURE:
		case UserActionTypes.USER_LOGOUT_FAILURE:
		case UserActionTypes.REGISTER_USER_FAILURE:
		case UserActionTypes.FETCH_ALL_USERS_FAILURE:
		case UserActionTypes.UPDATE_USER_FAILURE:
		case UserActionTypes.RESET_USER_PASSWORD_FAILURE:
		case UserActionTypes.FORGOT_PASS_FAILURE:
		case UserActionTypes.INSERT_USER_FAILURE:
		case UserActionTypes.DELETE_USER_FAILURE:
		case UserActionTypes.IMPORT_USERS_FAILURE:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case UserActionTypes.FETCH_USER_SUCCESS:
			return {
				...state,
				currentUser: action.payload,
				error: null,
				loading: false,
			};
		case UserActionTypes.SET_REMEMBER_ME:
			return {
				...state,
				rememberMe: action.payload,
			};
		case UserActionTypes.GET_ACCESS_TOKEN_SUCCESS:
			const { accessToken, refreshToken, provider, idToken } = action.payload;
			return {
				...state,
				token: accessToken,
				provider,
				refreshToken,
				idToken
			};
		case UserActionTypes.USER_LOGOUT_SUCCESS: {
			return {
				...state,
				token: null,
				refreshToken: null,
				idToken: null,
				provider: null,
				currentUser: null,
				loading: false,
			};
		}
		case UserActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS: {
			const { accessToken, refreshToken, idToken } = action.payload;
			return {
				...state,
				token: accessToken,
				refreshToken: refreshToken,
				idToken: idToken,
				loading: false,
			};
		}
		case UserActionTypes.REGISTER_USER_SUCCESS:
		case UserActionTypes.FORGOT_PASS_SUCCESS:
		case UserActionTypes.CHECK_ACCESS_TOKEN_SUCCESS:
		case UserActionTypes.IMPORT_USERS_SUCCESS:
		case UserActionTypes.DELETE_USER_SUCCESS:
		case UserActionTypes.UPDATE_USER_SUCCESS: {
			return {
				...state,
				loading: false,
			};
		}
		case UserActionTypes.SET_USER_ROLE: {
			return {
				...state,
				role: action.payload,
			};
		}
		default:
			return state;
	}
};

export default userReducer;
