import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearCubicError,
	fetchCubicFailure,
	fetchCubicSuccess,
	fetchQCPhotoFailure,
	fetchQCPhotoSuccess,
} from "./wood-cubic.action";
import QCActionTypes from "./wood-cubic.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser } from "../user/user.selector";

export function* fetchCubic({ payload }) {
	const filters = payload;
	const userCompany = yield select(selectCurrentUserCompany);
	const user = yield select(selectCurrentUser);
	const query = queryString.stringify({
		fltDeleted:0,
		fltUser: user.id,
		pageSize: 0,
		fltUseCaseType : "2",
		...filters
	});

	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/wood-countings?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const harvestActivities = response.data.records;
		yield put(fetchCubicSuccess(harvestActivities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearCubicError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchCubicFailure(errorMessage));
	}
}

export function* onFetchCubicStart() {
	yield takeLatest(
		QCActionTypes.FETCH_CUBIC_START,
		fetchCubic
	);
}


export function* CubicSagas() {
	yield all([call(onFetchCubicStart)]);
}
