import QCActivityActionTypes from "./wood-qc.types";

export const fetchQCStart = (filters) => ({
	type: QCActivityActionTypes.FETCH_QC_START,
	payload: filters,
});

export const fetchQCSuccess = (members) => ({
	type: QCActivityActionTypes.FETCH_QC_SUCCESS,
	payload: members,
});

export const fetchQCFailure = (error) => ({
	type: QCActivityActionTypes.FETCH_QC_FAILURE,
	payload: error,
});


export const clearQCError = () => ({
	type: QCActivityActionTypes.CLEAR_ERROR,
});
