import axios from "axios";
import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { BASE_URL_API } from "../../configs/env";
import { openSnackbar } from "../snackbar/snackbar.action";
import {
	clearPlanterError,
	fetchPlanterSuccess,
	fetchPlanterFailure,
	fetchPlanterTreeSuccess,
	fetchPlanterTreeFailure,
	fetchPlanterStart,
} from "./planter.action";
import ActivitiesActionTypes from "./planter.types";
import queryString from "query-string";
import { selectCurrentUserCompany } from "../company/company.selector";
import { selectCurrentUser } from "../user/user.selector";

export function* fetchPlanter({payload}) {
	const filter = payload;
	const company = yield select(selectCurrentUserCompany);
	const user = yield select(selectCurrentUser);
	const query = queryString.stringify({
		fltDeleted: 0,
		pageSize: 0,
		fltUserName:user.username,
		...filter
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/planting-sessions?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchPlanterSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPlanterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchPlanterFailure(errorMessage));
	}
}

export function* onFetchPlanterStart() {
	yield takeLatest(
		ActivitiesActionTypes.FETCH_PLANTER_START,
		fetchPlanter
	);
}

export function* fetchPlanterTree({payload}) {
	const filter = payload;
	const company = yield select(selectCurrentUserCompany);
	const query = queryString.stringify({
		fltDeleted: 0,
		pageSize: 0,
		...filter
	});
	try {
		const response = yield axios({
			method: "GET",
			url: `${BASE_URL_API}/planting-events?${query}`,
		});

		if (!response.data.success) {
			throw new Error(response.data.message);
		}

		const activities = response.data.records;

		yield put(fetchPlanterTreeSuccess(activities));
	} catch (error) {
		const errorMessage =
			(error.response && error.response.data.message) || error.message;
		const snackbarData = {
			message: errorMessage,
			color: "error",
			place: "bl",
			dispatchActions: [clearPlanterError],
		};
		yield put(openSnackbar(snackbarData));
		yield put(fetchPlanterTreeFailure(errorMessage));
	}
}

export function* onFetchPlanterTreeStart() {
	yield takeLatest(
		ActivitiesActionTypes.FETCH_PLANTER_TREE_START, fetchPlanterTree
	);
}

export function* planterSagas() {
	yield all([
		call(onFetchPlanterStart),
		call(onFetchPlanterTreeStart),
	]);
}
