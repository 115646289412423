const MapsActionTypes = {
	CLEAR_ERROR: "CLEAR_ERROR",
	// SET GEO_JSON
	SET_GEO_JSON: "SET_GEO_JSON",
	// SET TRUCK
	SET_TRUCK: "SET_TRUCK",
	// SET ACTIVITY
	SET_ACTIVITY: "SET_ACTIVITY",
	// SET START_DATE
	SET_START_DATE: "SET_START_DATE",
	// RESET FILTER
	RESET_MAPS_FILTER: "RESET_MAPS_FILTER",
	// Fetch TRUCKS
	FETCH_TRUCKS_START: "FETCH_TRUCKS_START",
	FETCH_TRUCKS_SUCCESS: "FETCH_TRUCKS_SUCCESS",
	FETCH_TRUCKS_FAILURE: "FETCH_TRUCKS_FAILURE",
	// Fetch TREES
	FETCH_TREES_START: "FETCH_TREES_START",
	FETCH_TREES_SUCCESS: "FETCH_TREES_SUCCESS",
	FETCH_TREES_FAILURE: "FETCH_TREES_FAILURE",
};

export default MapsActionTypes;
