import ActivitiesActionTypes from "./planter.types";

export const fetchPlanterStart = (username) => ({
	type: ActivitiesActionTypes.FETCH_PLANTER_START,
	payload: username,
});

export const fetchPlanterSuccess = (company) => ({
	type: ActivitiesActionTypes.FETCH_PLANTER_SUCCESS,
	payload: company,
});

export const fetchPlanterFailure = (error) => ({
	type: ActivitiesActionTypes.FETCH_PLANTER_FAILURE,
	payload: error,
});

export const fetchPlanterTreeStart = (username) => ({
	type: ActivitiesActionTypes.FETCH_PLANTER_TREE_START,
	payload: username,
});

export const fetchPlanterTreeSuccess = (company) => ({
	type: ActivitiesActionTypes.FETCH_PLANTER_TREE_SUCCESS,
	payload: company,
});

export const fetchPlanterTreeFailure = (error) => ({
	type: ActivitiesActionTypes.FETCH_PLANTER_TREE_FAILURE,
	payload: error,
});

export const clearPlanterError = () => ({
	type: ActivitiesActionTypes.CLEAR_ERROR,
});
